import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { S, STYLES } from 'x/config/styles'
import CONS from 'x/config/constants'
import {
  IBarcodeScannerOutput,
  IOrder,
  IShippingTrackingNumberCardProps,
  IShippingTrackingNumberInfo,
  ICustomBarcodeRenderProps,
} from 'x/index'

import { IParcel } from 'x/modules/order/BaseMultiParcelView'
import CollapsableCard from '../CollapsableCard'
import BarcodeScannerWithSegment from '../BarcodeScannerWithSegment'
import XText from '../XText'

interface ISavedState {
  segmentBarcodeIndex?: number
  isLatestSegmentBarcodeUnfill?: boolean
}

const ShippingTrackingNumberCard: React.FC<IShippingTrackingNumberCardProps> = (props) => {
  // const isXShipping = props.order ? isXShippingOrder(props.order) : false
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)
  const [isXShipping, setIsXShipping] = React.useState(false)
  const [isTrackingNumberSubmittedAtLeastOnce, setIsTrackingNumberSubmittedAtLeastOnce] = React.useState(false)

  const [availableXShippingTrackingNumbers, setAvailableXShippingTrackingNumbers] = React.useState<string[]>([])
  const [finishedTrackingInfo, setSubmittedTrackingInfo] = React.useState<IShippingTrackingNumberInfo>(null)
  const [isBarcodeRead, setIsBarcodeRead] = React.useState<boolean>(false)
  const [isForceExpanded, setIsForceExpanded] = React.useState<boolean>(false)
  const [segmentBarcodeIndex, setSegmentBarcodeIndex] = React.useState<number>(0)

  const isPreventExpand = props.skip && _.isNil(finishedTrackingInfo) && !isTrackingNumberSubmittedAtLeastOnce
  // console.log('storeXShippings => ', props.storeXShippings)
  React.useEffect(() => {
    if (props.order || props.multiParcel || props.isSelectedAllProduct) {
      _initialize()
    }
  }, [props.order, props.multiParcel, props.isSelectedAllProduct])

  React.useEffect(() => {
    if (isInitialized) {
      if (props.disabled && !props.expanded) {
        setIsForceExpanded(false)
      }

      if (!props.disabled && props.expanded && _.isNil(finishedTrackingInfo)) {
        setIsForceExpanded(true)

        // setTimeout(() => _autoSubmitTrackingNumbers(), 1200)
        if (!isTrackingNumberSubmittedAtLeastOnce) {
          setSegmentBarcodeIndex(0)
          if (isXShipping && availableXShippingTrackingNumbers.length > 0 && props.isSelectedAllProduct) {
            _submitXShippingTrackingNumber()
          } else if (props.skip) {
            _submitUnfillTrackingNumber()
          }
        }
      }

      // console.log('isXShipping = ', isXShipping)
      // console.log('availableXShippingTrackingNumbers = ', availableXShippingTrackingNumbers)
      // if (!isXShipping && isTrackingNumberSubmittedAtLeastOnce && availableXShippingTrackingNumbers.length > 0) {
      //   _submitXShippingTrackingNumber()
      // }

      // if (!isXShipping && props.skip) {
      //   _submitUnfillTrackingNumber()
      // }
    }
  }, [isInitialized, props.disabled, props.expanded, props.skip])

  // const _autoSubmitTrackingNumbers = React.useCallback(async () => {
  //   await p.op.alertPromise(
  //     '_autoSubmitTrackingNumbers isTrackingNumberSubmittedAtLeastOnce => ',
  //     JSON.stringify(isTrackingNumberSubmittedAtLeastOnce)
  //   )
  //   if (!isTrackingNumberSubmittedAtLeastOnce) {
  //     await p.op.alertPromise('_autoSubmitTrackingNumbers isXShipping => ', JSON.stringify(isXShipping))
  //     await p.op.alertPromise(
  //       '_autoSubmitTrackingNumbers availableXShippingTrackingNumbers => ',
  //       JSON.stringify(availableXShippingTrackingNumbers)
  //     )
  //     await p.op.alertPromise('_autoSubmitTrackingNumbers props.skip => ', JSON.stringify(props.skip))
  //     if (isXShipping && availableXShippingTrackingNumbers.length > 0) {
  //       _submitXShippingTrackingNumber()
  //     } else if (props.skip) {
  //       _submitUnfillTrackingNumber()
  //     }
  //   }
  // }, [props.skip, isTrackingNumberSubmittedAtLeastOnce, isXShipping, availableXShippingTrackingNumbers])

  const _submitXShippingTrackingNumber = async () => {
    // console.log('do _submitXShippingTrackingNumber()')
    setIsBarcodeRead(true)
    await _submitTrackingNumber({ tracking_numbers: availableXShippingTrackingNumbers })
    setIsBarcodeRead(false)
  }

  const _submitTrackingNumber = async (trackingInfo: IShippingTrackingNumberInfo) => {
    // Keng 23/10/2021: Workaround to always use "tracking_number" instead of "tracking_numbers" to send for now
    // until we adjust the product json structure to match with the following laravel code
    // ======= ShippingController.php line 110
    // if (!array_key_exists("p", $orderGen->pp_qty)) { // expect to get {p:[{pp_id:... , qty:... }, { ... }]}
    // =======
    let submittedTf
    const tf = trackingInfo
    // @ts-ignore
    if (_.isArray(tf.tracking_numbers)) {
      // @ts-ignore
      if (tf.tracking_numbers.length === 1) {
        // @ts-ignore
        submittedTf = { tracking_number: tf.tracking_numbers[0] }
      } else {
        submittedTf = tf
      }
    } else {
      submittedTf = tf
    }

    if (!isTrackingNumberSubmittedAtLeastOnce) {
      setIsTrackingNumberSubmittedAtLeastOnce(true)
    }

    // await props.onFinish({ trackingNumbers })
    await props.onFinish(submittedTf)
    setSubmittedTrackingInfo(submittedTf)
    // setIsFinished(true)
    setIsForceExpanded(false)
  }

  const _onBarcodeScanned = async (barcode: IBarcodeScannerOutput) => {
    // console.log('_onBarcodeScanned barcode => ', barcode)
    if (barcode.data && !isBarcodeRead) {
      setIsBarcodeRead(true)

      const barcodeText = barcode.data
      if (barcodeText === props.order.id.toString()) {
        const isUserConfirm = await p.op.isUserConfirm('ใช้เลขพัสดุเลขเดียวกับออเดอร์?', '', 'ใช่', 'ไม่')
        if (!isUserConfirm) {
          setIsBarcodeRead(false)
          return
        }
      }

      await _submitTrackingNumber({ tracking_number: barcodeText })

      await util.delay(500)
      setIsBarcodeRead(false)
    }
  }

  const _initialize = async () => {
    // Load saved state
    const savedState = (await util.loadDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_TRACKING_NUMBER_CARD)) as ISavedState

    const isOrderXShipping = isXShippingOrder(props.order)
    setIsXShipping(isOrderXShipping)

    let isDefaultBarcodeSegmentAlreadySet = false
    if (isOrderXShipping) {
      const availableTrackingNumbers = getXShippingAvailableToShipTrackingNumbers(props.multiParcel)
      setAvailableXShippingTrackingNumbers(availableTrackingNumbers)

      if (availableTrackingNumbers.length > 0) {
        // ถ้าเป็น xshipping ให้ default segment index ไปที่ xshipping ก่อน (ครั้งแรก)
        setSegmentBarcodeIndex(_getSegmentXShippingIndex())
        isDefaultBarcodeSegmentAlreadySet = true
      }
    }

    if (savedState && !isDefaultBarcodeSegmentAlreadySet) {
      // ถ้าไม่ใช่ xshipping ให้โหลดตามปกติ
      if (savedState.isLatestSegmentBarcodeUnfill) {
        setSegmentBarcodeIndex(_getSegmentUnfillIndex(isOrderXShipping))
      } else if (_.isInteger(savedState.segmentBarcodeIndex) && savedState.segmentBarcodeIndex > -1) {
        setSegmentBarcodeIndex(savedState.segmentBarcodeIndex)
      }
    }

    setIsInitialized(true)
  }

  const _onPressRevert = async () => {
    const confirmmedRevert = await props.onRequestRevert()
    if (!confirmmedRevert) {
      return
    }
    setIsForceExpanded(false)
    setIsBarcodeRead(false)
    setSubmittedTrackingInfo(null)
  }

  const _submitUnfillTrackingNumber = async () => {
    await _submitTrackingNumber({ tracking_number: '-' })
  }

  const _renderUnfillTrackingOption = (crProps: ICustomBarcodeRenderProps) => (
    <View style={[S.COLUMN_CENTER_TOP, S.BG_LIGHT_GREY, crProps.containerStyle]}>
      {/* <View style={[S.ROW_CENTER, S.MARGIN_TOP_12, { width: crProps.containerStyle.width }]}>
          <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER, S.TEXT_ALIGN_CENTER]}>{'ไม่ระบุเลขพัสดุ'}</XText>
        </View> */}

      <View style={[S.COLUMN_CENTER, S.MARGIN_VERTICAL_6, { width: crProps.containerStyle.width }]}>
        <View style={[S.ROW_CENTER, S.MARGIN_TOP_6]}>
          <XText bold textAlign='center'>
            -
          </XText>
        </View>
      </View>

      <TouchableOpacity
        style={[
          S.BORDER,
          S.BORDERS_PRIMARY,
          S.BORDER_RADIUS_8,
          S.HEIGHT_34,
          S.BG_PRIMARY,
          S.PADDING_VERTICAL_4,
          S.PADDING_HORIZONTAL_8,
          S.ROW_CENTER,
          S.MARGIN_TOP_6,
        ]}
        onPress={_submitUnfillTrackingNumber}>
        <XText variant='activeDark' bold>
          ยืนยันไม่ระบุเลขพัสดุ
        </XText>
      </TouchableOpacity>

      <View style={[S.ROW_CENTER, S.MARGIN_TOP_16, { width: crProps.containerStyle.width }]}>
        <XText variant='inactive' textAlign='center'>
          การไม่ระบุเลขพัสดุคือการระบุ ขีด (-) ลงไปในระบบ โดยไม่สนใจว่าจะเช็คสถานะพัสดุได้หรือไม่
        </XText>
      </View>
    </View>
  )

  const _renderXShippingTrackingOption = (crProps: ICustomBarcodeRenderProps) => {
    if (!props.xShippingEnabled) {
      return _renderXShippingWarningInfo(crProps, 'ร้านนี้ไม่ได้เปิดใช้ XShipping')
    }

    const orderShippingTypeId = props.order.shipping_type_id
    const isXShippingTypeEligible = _isShippingTypeIdEligibleToXShipping(orderShippingTypeId)
    const shippingTypeItem = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.find((spi) => spi.value === orderShippingTypeId)
    const shippingTypeName = p.op.t(`Order.shippingTypeItems.${shippingTypeItem.key}`)

    if (!isXShippingTypeEligible) {
      return _renderXShippingWarningInfo(
        crProps,
        `ประเภทจัดส่งที่ระบุในออเดอร์นี้\nไม่ได้เปิดการใช้งาน XShipping ไว้ (${shippingTypeName})`
      )
    }
    const availableTrackingLength = availableXShippingTrackingNumbers.length

    if (availableTrackingLength === 0) {
      return _renderXShippingWarningInfo(crProps, `ยังไม่มีเลขพัสดุพร้อมส่งที่มาจากระบบ (${shippingTypeName})`)
    }

    const availableTrackingText = `พบ ${availableTrackingLength} เลขติดตามพัสดุจาก XShipping`
    return (
      <View style={[S.COLUMN_CENTER_TOP, S.BG_LIGHT_GREY, { justifyContent: 'space-between' }, crProps.containerStyle]}>
        <View style={[S.ROW_CENTER, S.MARGIN_TOP_12, { width: crProps.containerStyle.width }]}>
          <XText textAlign='center'>{availableTrackingText}</XText>
        </View>

        <View style={[S.COLUMN_CENTER, S.MARGIN_VERTICAL_4, { width: crProps.containerStyle.width }]}>
          {availableXShippingTrackingNumbers.map((trackingString, idx) => (
            <View key={idx.toString()} style={[S.ROW_CENTER, { marginTop: 4 }]}>
              <XText bold textAlign='center'>
                {trackingString}
              </XText>
            </View>
          ))}
        </View>

        {availableTrackingLength ? (
          <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4, { width: crProps.containerStyle.width }]}>
            <XText variant='inactive' textAlign='center'>
              เป็นเลขที่ถูกสร้างขึ้นโดยระบบ
            </XText>
          </View>
        ) : null}

        <TouchableOpacity
          style={[
            S.BORDER,
            S.BORDERS_PRIMARY,
            S.BORDER_RADIUS_8,
            S.HEIGHT_34,
            S.BG_PRIMARY,
            S.PADDING_VERTICAL_4,
            S.PADDING_HORIZONTAL_8,
            S.ROW_CENTER,
            S.MARGIN_VERTICAL_12,
          ]}
          onPress={_submitXShippingTrackingNumber}>
          <XText variant='activeDark' bold>
            ยืนยันเลขพัสดุ
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  const UNFILL_BARCODE_TRACKING_OPTION = {
    label: 'ไม่ระบุ',
    key: 'unfill',
    customRender: _renderUnfillTrackingOption,
  }
  const XSHIPPING_BARCODE_TRACKING_OPTION = {
    label: 'XShippping',
    key: 'xshipping',
    customRender: _renderXShippingTrackingOption,
  }

  const _getExtraSegmentOptions = () => {
    if (isXShipping) {
      return [XSHIPPING_BARCODE_TRACKING_OPTION, UNFILL_BARCODE_TRACKING_OPTION]
    }
    return [UNFILL_BARCODE_TRACKING_OPTION]
  }
  const _getSegmentXShippingIndex = () => (p.op.isWeb() ? 1 : 2)
  const _getSegmentUnfillIndex = (isOrderXShipping: boolean) => {
    if (isOrderXShipping) {
      return p.op.isWeb() ? 2 : 3
    }
    return p.op.isWeb() ? 1 : 2
  }

  const _isShippingTypeIdEligibleToXShipping = (shippingTypeId: number) => {
    if (!props.storeXShippings || props.storeXShippings.length === 0) {
      return false
    }
    const xshipping = props.storeXShippings
    for (let i = 0; i < xshipping.length; i++) {
      const xShippingTypeId = xshipping[i].shipping_type_id
      if (xShippingTypeId === shippingTypeId) {
        return true
      }
    }
    return false
  }

  const _renderXShippingWarningInfo = (crProps: ICustomBarcodeRenderProps, infoText: string) => (
    <View style={[S.COLUMN_CENTER, S.BG_GREY, crProps.containerStyle]}>
      <XText textAlign='center'>{infoText}</XText>
    </View>
  )

  const _onBarcodeSegmentChange = async (newIndex: number) => {
    setSegmentBarcodeIndex(newIndex)
    if (newIndex === _getSegmentXShippingIndex()) {
      return
    }

    if (newIndex === _getSegmentUnfillIndex(isXShipping)) {
      // save unfill segment state
      await util.saveDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_TRACKING_NUMBER_CARD, {
        segmentBarcodeIndex: -1,
        isLatestSegmentBarcodeUnfill: true,
      })
      return
    }

    // save segment state
    await util.saveDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_TRACKING_NUMBER_CARD, {
      segmentBarcodeIndex: newIndex,
      isLatestSegmentBarcodeUnfill: false,
    })
  }

  const renderBarcodeScanner = () => {
    if (finishedTrackingInfo || props.disabled || !props.expanded || !props.order) {
      return null
    }
    const key = `BarcodeTracking_${props.order && props.order.id ? props.order.id.toString() : '0'}_${isXShipping}`

    // let initialSegmentIndex = 0

    // if (isXShipping) {
    //   const availableTrackingNumbers = getXShippingAvailableToShipTrackingNumbers(props.order)
    //   const availableTrackingLength = availableTrackingNumbers.length
    //   if (availableTrackingLength > 0) {
    //     initialSegmentIndex = _getSegmentXShippingIndex()
    //   }
    // }

    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP]}>
        <BarcodeScannerWithSegment
          key={key}
          saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_SHIPPING_TRACKING_NUMBER}
          inputPlaceholder='ระบุเลขพัสดุ...'
          disabled={isBarcodeRead}
          // initialIndex={initialSegmentIndex}
          onBarcodeScanned={_onBarcodeScanned}
          selectedIndex={segmentBarcodeIndex}
          onChangeSegment={_onBarcodeSegmentChange}
          extraSegmentOptions={_getExtraSegmentOptions()}
          segmentTextStyle={isXShipping ? { fontSize: STYLES.FONT_SIZE_NORMAL } : {}}
        />
      </View>
    )
  }

  const _getSubmittedTrackingNumbers = () => {
    const fti = finishedTrackingInfo
    // @ts-ignore
    const submittedTrackingNumbers: string[] = fti.tracking_number ? [fti.tracking_number] : fti.tracking_numbers
    if (_.isNil(submittedTrackingNumbers)) {
      return []
    }
    return submittedTrackingNumbers
  }

  const _renderTrackingNumberSummary = () => {
    if (!finishedTrackingInfo || _.isEmpty(finishedTrackingInfo)) {
      return null
    }
    const submittedTrackingNumbers: string[] = _getSubmittedTrackingNumbers()
    const trackingLength = submittedTrackingNumbers.length
    if (trackingLength === 0) {
      return null
    }

    const textTracking = trackingLength === 1 ? submittedTrackingNumbers[0] : `${submittedTrackingNumbers[0]}, (${trackingLength - 1})`

    return (
      <View style={[trackingLength > 1 ? S.COLUMN_LEFT_MIDDLE : S.COLUMN_RIGHT_MIDDLE, { minWidth: 120 }]}>
        <XText style={[S.TEXT_SUCCESS, S.TEXT_BOLD, S.TEXT_LARGER_2]}>{textTracking}</XText>
        {/* {submittedTrackingNumbers.map((stn, idx) => (
          <XText key={idx.toString()} style={[S.TEXT_SUCCESS, S.TEXT_BOLD, S.TEXT_LARGER_2]}>
            {stn}
          </XText>
        ))} */}
      </View>
    )
  }

  const renderContent = () => {
    if (!isInitialized) {
      return null
    }
    // if (!props.products || props.products.length === 0) {
    //   return (
    //     <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
    //       <XText>ไม่มีสินค้าที่ต้องจัดส่ง</XText>
    //     </View>
    //   )
    // }

    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP, S.PADDING_8]}>
        {/* <AutocompleteChecker
          availableXShippingTrackingNumbers={availableXShippingTrackingNumbers}
          isForceExpanded={isForceExpanded}
          isSkip={props.skip}
          isXShipping={isXShipping}
          isTrackingNumberSubmittedAtLeastOnce={isTrackingNumberSubmittedAtLeastOnce}
          submitUnfillTrackingNumber={_submitUnfillTrackingNumber}
          submitXShippingTrackingNumber={_submitXShippingTrackingNumber}
        /> */}
        {finishedTrackingInfo && !_.isEmpty(finishedTrackingInfo) ? renderShippingTrackingNumberDetial() : renderBarcodeScanner()}
      </View>
    )
  }

  const renderShippingTrackingNumberDetial = () => {
    const submittedTrackingNumbers: string[] = _getSubmittedTrackingNumbers()
    const trackingText = submittedTrackingNumbers.join(', ')
    const selectedShippingTypeText = util.getShippingTypeNameById(props.order.shipping_type_id)
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP]}>
        <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP]}>
          <XText variant='inactive' textAlign='center'>
            ระบุเลขพัสดุเสร็จสิ้นแล้ว
          </XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          <XText variant='inactive'>เลขพัสดุ</XText>
          <XText>{trackingText}</XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
          <XText variant='inactive'>รูปแบบจัดส่ง</XText>
          <XText>{selectedShippingTypeText}</XText>
        </View>
        <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_16]}>
          <TouchableOpacity
            style={[
              S.BORDER,
              S.BORDERS_INACTIVE,
              S.BORDER_RADIUS_8,
              S.HEIGHT_34,
              S.BG_WHITE,
              S.ROW_CENTER,
              S.PADDING_VERTICAL_4,
              S.PADDING_HORIZONTAL_8,
            ]}
            onPress={_onPressRevert}>
            <XText textAlign='center'>แก้ไขเลขพัสดุ</XText>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  return (
    <CollapsableCard
      disabledAnimation
      disabled={isForceExpanded}
      expanded={props.expanded && !isPreventExpand}
      onPressToggle={props.onPressToggle}
      renderContent={renderContent}>
      <View
        style={[
          S.HEIGHT_44,
          S.WIDTH_FULL,
          S.ROW_MIDDLE_BETWEEN,
          S.PADDING_8,
          props.disabled ? S.BG_GREY : S.BG_SECONDARY,
          finishedTrackingInfo ? S.BG_SUCCESS : {},
        ]}>
        <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
          <XText variant={isForceExpanded ? 'active' : 'inactive'} bold>
            {finishedTrackingInfo ? '3. เลขพัสดุ' : '3. ระบุเลขพัสดุ'}
          </XText>
        </View>
        {_renderTrackingNumberSummary()}
      </View>
      {/* <TouchableOpacity onPress={() => _onBarcodeScanned({ data: 'TESTABCD1234' })}>
        <XText>DEBUG:: Scan TESTABCD1234</XText>
      </TouchableOpacity> */}
    </CollapsableCard>
  )
}

// inside componnents
// const AutocompleteChecker = ({
//   isForceExpanded,
//   isXShipping,
//   isSkip,
//   isTrackingNumberSubmittedAtLeastOnce,
//   availableXShippingTrackingNumbers,
//   submitXShippingTrackingNumber,
//   submitUnfillTrackingNumber,
// }) => {
//   // React.useEffect(() => {
//   //   _doAutocompleteTracking()
//   // }, [])
//   const _doAutocompleteTracking = () => {
//     // console.log('isXShipping = ', isXShipping)
//     // console.log('availableXShippingTrackingNumbers = ', availableXShippingTrackingNumbers)
//     if (!isTrackingNumberSubmittedAtLeastOnce) {
//       if (isXShipping && availableXShippingTrackingNumbers.length > 0) {
//         submitXShippingTrackingNumber()
//       } else if (!isXShipping && isSkip) {
//         submitUnfillTrackingNumber()
//       }
//     }
//   }

//   React.useEffect(() => {
//     if (isForceExpanded) {
//       _doAutocompleteTracking()
//     }
//   }, [isForceExpanded])

//   return null
// }

// compute functions
function isXShippingOrder(order: IOrder) {
  return order && !_.isEmpty(order.x)
}

// ues multiParcel ref => https://app.clickup.com/t/86cxg9ecj
function getXShippingAvailableToShipTrackingNumbers(multiParcel: IParcel[]) {
  // const orderGenList = _.has(order, ['x', 'o']) ? order.x.o : []
  // console.log('multiParcel // => ', multiParcel)
  // Extract the order generation list from the multiParcel array
  const orderGenList = multiParcel.filter((parcel) => parcel.x && parcel.x.o).map((parcel) => parcel.x.o[0])
  // console.log('orderGenList // => ', orderGenList)
  // @ts-ignore
  const availableToShipList = orderGenList.filter((og) => isXShippingTrackingShipable(og.s))
  return availableToShipList.map((availableItem) => availableItem.t)
}

function isXShippingTrackingShipable(trackingState: number) {
  // https://docs.google.com/spreadsheets/d/1F2ebfuCyFB4a3Z6g0MUZRocMvjNvnoyVkrKwS6Am-xw/edit?usp=sharing
  // อันนี้เป็น ชีท Courier Order State ตัว tracking code ที่ให้ยิงเลขพัสดุได้ ให้ดูที่ column "TrackingCodePrintable?" นะครับ
  //
  // ได้ทั้ง YES และ YES if DB has it
  // สรุป ก็จะมีเลข
  // 1809
  // 2010 - 2701
  // 9001 - 9009
  return trackingState === 1909 || (trackingState >= 2010 && trackingState <= 2701) || (trackingState >= 9001 && trackingState <= 9009)
}

ShippingTrackingNumberCard.displayName = 'ShippingTrackingNumberCard'
// ShippingTrackingNumberCard.defaultProps = {}

export default ShippingTrackingNumberCard
